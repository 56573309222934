/* .all_brand_wrap {
  background-image: url(../../lib/images/allbrandbg.png);
  background-position: center;
  /* background-repeat: repeat;
  background-size: cover;
   */
/* height: 100vh;
  width: 100%; */
/* } */

.all_brand_wrap {
  background-color: #031b34;
  padding: 30px 0px;
}
