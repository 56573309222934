.levbitz_single_blog_wrap {
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.levbitz_single_blog_wrap img {
  border-radius: 10px;
}

.levbitz_single_blog_wrap h5 {
  color: #000;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}
