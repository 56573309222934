.prico_type {
  border: 1px solid #031b34;
  font-size: 12px;
  color: #031b34;
  font-weight: 700;
  padding: 0px 5px;
  border-radius: 5px;
}

.levbitz_thumbNail:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.tumbnail_price :hover {
  border-bottom: 2px red solid;
  /* transform: scale(1.1); */
}

.thumbNail_title {
  font-weight: 700;
}

/* .levbitz_thumbNail {
  z-index: -2000 !important;
} */
