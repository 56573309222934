.levbitz_single_car_wrap p {
  font-size: 14px;
  line-height: 1.5;

  margin-bottom: 10px;
}

.levbitz_single_car_wrap img {
  border-radius: 10px;
}

.levbitz_single_car_overview {
  border-radius: 15px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.levbitz_single_car_overview h3 {
  font-size: 23px;
  font-weight: 600;
  margin: 0px;
}

.levbitz_single_car_overview hr {
  margin: 10px 0px;
}

.levbitz_single_car_wrap .key {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #000;
  font-weight: 700;
}

.levbitz_single_car_wrap h5 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;

  font-weight: 600;
}

.levbitz_buy_direct {
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px 20px;
}

.levbitz_single_car_name_and_price {
  margin-top: 0px;
  border-radius: 10px;
  padding: 10px 20px;
}

.img_popping_wrap {
  position: fixed;
  position: absolute;

  top: 0;
  padding-top: 60px;
  z-index: 9999;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  height: 100%;
  min-height: 600vh;

  /* overflow-y: scroll; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  background: #031b34;

  /* padding-top: "3vh"; */
}

@media only screen and (max-width: 600px) {
  .levbitz_single_car_overview {
    margin-top: 80px;
  }
}
