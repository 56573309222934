.lb_nav_wrap {
  /* background: transparent; */
  /* background: #283593; */
  background: #1a237e;
  padding: 0px;
  transition: all 0.4s ease 0s;
  height: 60px;
  display: grid;
  align-items: center;
  box-shadow: none;
  z-index: 9999 !important;
  width: 100%;
  padding: 0px 140px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  margin: auto;

  z-index: 1030;
}

@media only screen and (max-width: 600px) {
  .lb_nav_wrap {
    background: #1a237e;
    padding: 0px;
    transition: all 0.4s ease 0s;
    height: 60px;
    display: grid;
    align-items: center;
    box-shadow: none;
    z-index: 99;
    /* background: rgb(8, 7, 19); */
    /* border: 0.1px coral solid; */
    /* border-radius: 10px; */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    margin: auto;
    /* margin-top: 5px; */
    z-index: 1030;
  }
}

.lb_active {
  background: #031b34;
  padding: 0px 140px;

  transition: all 0.4s ease 0s;
  height: 60px;
  display: grid;
  align-items: center;
  box-shadow: none;
  z-index: 99;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media only screen and (max-width: 600px) {
  .lb_active {
    background: #031b34;
    padding: 0px 0px;

    transition: all 0.4s ease 0s;
    height: 60px;
    display: grid;
    align-items: center;
    box-shadow: none;
    z-index: 99;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}

.nav-wrapper {
  width: 100%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .navbar {
    background: #064757;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100% !important;
  }
}

.navbar-logo {
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .navbar-logo {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-left: -30px !important;
    margin-top: -30px !important;
  }
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);

  list-style: none;
  text-align: center;
  /* width: 100vw; */
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 50px;

  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .nav-item {
    display: flex;
    align-items: center;

    margin-bottom: 50px;
    font-weight: bold;
  }
}

.nav-links {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
}
@media screen and (max-width: 960px) {
  .nav-links {
    color: white;
    text-decoration: none;
    font-size: 20px;
    border-radius: 20px;
    text-transform: capitalize;
    /* border: 1px #fff solid; */
    margin-bottom: 50px;
    height: 30px !important;
    padding: 0px !important;
  }
}

.fa-bars {
  color: #fff;
  font-size: 20px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  margin-left: -40px !important;
  margin-top: -30px !important;
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: none;
    margin-left: -40px !important;
    margin-top: -30px !important;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 55px;
    left: -120%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .nav-menu.active {
    background: #2caee2;

    left: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    min-height: 0vh !important;
  }

  .nav-links {
    text-align: center;
    padding: 6rem;
    width: 80%;
    display: table;
    margin: auto;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  @media screen and (max-width: 960px) {
    .fa-times {
      color: #fff;
      font-size: 2rem;
      margin-right: -10px;
    }
    .fa-bars {
      color: #fff;
      font-size: 2rem;
      margin-right: -10px;
    }
  }

  button {
    display: none;
  }
}
.account {
  font-weight: bold;
  color: #064757;
  background: #fff;
}
@media only screen and (max-width: 720px) {
  .account {
    font-weight: bold;
    color: #fff;
    background: #064757;

    width: 100%;
  }
}

.mylogo {
  width: 160px;
  margin-top: 15px;
}

/* @media only screen and (max-width: 620px) {
  .mylogo {
    width: 100px;
    margin-top: 15px;
  }
} */

nav i,
nav [class^="mdi-"],
nav [class*="mdi-"],
nav i.material-icons {
  display: block;
  font-size: 24px;
  height: 0px !important;
  line-height: 56px;
}
