.levbitz_account_link_bg {
  background-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: -5px;
}

@media screen and (max-width: 600px) {
  .levbitz_account_link_bg {
    background-color: #011e32;
    font-weight: bold;
    border: 1px white solid;
  }
}
.levbitz_account_link_bg:hover {
  color: coral;
  transition: 2s ease;
}
